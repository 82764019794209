import Main from "../main/Main";
import ProductPlace from "../productPlace/ProductPlace";
import VerticalVideo from "../verticalVideo/VerticalVideo";
import AboutMe from "../aboutme/AboutMe";
import Contact from "../contact/Contact";
import Galerry from "../gallery/Galerry";

export default function Ksart(){
    return (
      <main>
          <Main />
          <ProductPlace />
          {/*<VerticalVideo />*/}
          <AboutMe />
          {/*<Galerry />*/}
          <Contact />
      </main>
    );
}
