import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../authorization/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import ImageItem from "../components/ImageItem";

export default function AdminProductDetails() {
    const { isAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const [product, setProduct] = useState({});

    const [title, setTitle] = useState('');
    const [descriptionEng, setDescriptionEng] = useState('');
    const [descriptionDe, setDescriptionDe] = useState('');
    const [price, setPrice] = useState('');
    const [sale_price, setSalePrice] = useState('');
    const [uploadedPaths, setUploadedPaths] = useState([]);
    const [uploadDescriptionSuccesful, setUploadDescriptionSuccesful] = useState(false);

    const [images, setImages] = useState([]);


    useEffect(() => {
        if (!isAuth) navigate("/");
    }, [isAuth]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Send username and password to server for authentication
                const response = await fetch('https://ksartbykristinaandrus.de/api/records', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ method: "11", productId: id }),
                });
                const data = await response.json();
                setProduct(data);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (Object.entries(product).length > 0) {
            setTitle(product.name);
            setUploadedPaths(JSON.parse(product.image));
            setPrice(product.price);
            setSalePrice(product.sale_price);
            getDescriptions(product.description_id)
            // getDescriptions(product.description_id);
        }
    }, [product]);

    // Handle file selection
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validFiles = selectedFiles.filter(file => {
            const fileType = file.type.split('/')[1];
            return ['jpeg', 'jpg', 'png', 'svg'].includes(fileType);
        });
        setImages(prevImages => [...prevImages, ...validFiles]);
    };

    function getDescriptions(id) {
        try {
            fetch('https://ksartbykristinaandrus.de/api/records', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ method: "12", descriptionId: id })
            })
                .then(response => response.json())
                .then(data => {
                    setDescriptionEng(data.eng);
                    setDescriptionDe(data.de);
                })
                .catch(error => {
                    console.error('Error uploading files:', error);
                });
        } catch (e) {
            console.error('Error uploading files:', e);
        }
    }

    const handleSubmit = (event) => {
        if (!window.confirm('Are you confirm changes?')) {
            return;
        }
        event.preventDefault();

        fetch('https://ksartbykristinaandrus.de/api/records', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ method: "13", descriptionId: product.description_id, eng: descriptionEng, de: descriptionDe }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Upload successful:', data);
                setUploadDescriptionSuccesful(data === 1);
            })
            .catch(error => {
                console.error('Error uploading files:', error);
            });
        // You can perform further processing, such as sending the data to the server, here
        // console.log(uploadedPaths, title, descriptionEng, descriptionDe, price );
    };

    useEffect(() => {
        if (uploadDescriptionSuccesful) {
            fetch('https://ksartbykristinaandrus.de/api/records', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ method: "14", name: title, image: JSON.stringify(uploadedPaths), descriptionId: product.description_id, price: price, productId: product.id}),
            })
                .then(response => response.json())
                .then(data => {
                    window.alert('Product added successfully: ' + data.success);
                })
                .catch(error => {
                    console.error('Error adding product:', error);
                });
        }
    }, [uploadDescriptionSuccesful]);

    const handleUpload = () => {
        const formData = new FormData();
        images.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });
        formData.append('method', 'upload_product_images');

        fetch('https://ksartbykristinaandrus.de/api/records', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('Upload successful:', data);
                setImages([]);
                setUploadedPaths(prevImages => [...prevImages, ...data.paths]);
            })
            .catch(error => {
                console.error('Error uploading files:', error);
            });
    };

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = (e, targetIndex) => {
        e.preventDefault();
        const sourceIndex = e.dataTransfer.getData('index');
        const updatedImages = [...uploadedPaths];
        const item = updatedImages.splice(Number(sourceIndex), 1)[0];
        updatedImages.splice(targetIndex, 0, item);
        setUploadedPaths(updatedImages);
    };

    const handleRemoveUploadPath = (image) => {
        setUploadedPaths(uploadedPaths.filter(item => item !== image))
    };

    const handleRemoveImage = (image) => {
        setImages(images.filter(item => item.name !== image.name))
    };

    return (
        <div className="container">
            <input type="file" multiple onChange={handleFileChange} style={{
                marginTop: 50
            }}/>
            <button onClick={handleUpload}>Upload</button>

            <div className="row" style={{
                marginTop: 50
            }}>
                {uploadedPaths.map((image, index) => (
                    <div className="col-lg-2" key={index}>
                        <ImageItem image={image} link={image} index={index} onDragStart={(e) => handleDragStart(e, index)} handleDragOver={handleDragOver} onDrop={(e) => handleDrop(e, index)} removeImage={handleRemoveUploadPath}/>
                    </div>
                ))}
                {images.map((image, index) => (
                    <div className="col-lg-2" key={index}>
                        <ImageItem image={image} link={URL.createObjectURL(image)} index={index} onDragStart={() => console.log("click")} handleDragOver={() => console.log("click")} onDrop={() => console.log("click")} removeImage={handleRemoveImage}/>
                    </div>
                ))}
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row" style={{
                    marginTop: 50
                }}>
                    <label className="col-lg-2">Title:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                        required
                        className="col-lg-4"
                    />
                </div>
                <div className="row" style={{
                    marginTop: 50
                }}>
                    <label className="col-lg-2">Description (English):</label>
                    <textarea
                        value={descriptionEng}
                        onChange={(event) => setDescriptionEng(event.target.value)}
                        required
                        className="col-lg-4"
                    />
                </div>
                <div className="row" style={{
                    marginTop: 50
                }}>
                    <label className="col-lg-2">Description (German):</label>
                    <textarea
                        value={descriptionDe}
                        onChange={(event) => setDescriptionDe(event.target.value)}
                        required
                        className="col-lg-4"
                    />
                </div>
                <div className="row" style={{
                    marginTop: 50
                }}>
                    <label className="col-lg-2">Price:</label>
                    <input
                        type="number"
                        value={price}
                        onChange={(event) => setPrice(event.target.value)}
                        required
                        className="col-lg-4"
                    />
                </div>
                <div className="row" style={{
                    marginTop: 50
                }}>
                    <label className="col-lg-2">Price:</label>
                    <input
                        type="number"
                        value={sale_price}
                        onChange={(event) => setSalePrice(event.target.value)}
                        required
                        className="col-lg-4"
                    />
                </div>
                <div className="d-flex justify-content-center" style={{
                    marginTop: 50
                }}>
                    <button type="submit" style={{
                        border: "none",
                        backgroundColor: "#ef8d8d",
                        padding: "12px 46px",
                        margin: "auto", borderRadius: 16,
                    }} disabled={uploadedPaths.length === 0 || images.length > 0}>Submit</button>
                </div>

            </form>
        </div>
    );
}